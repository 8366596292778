import TestimonialsCarousel from "@/components/data-display/TestimonialsCarousel";
import TestimonialCard from "@/components/data-display/card/TestimonialCard";
import CategoryHeading from "@/components/data-display/categoryHeading";

const Testimonials = ({ data, className }) => {
  return (
    <div
      className={`mb-[3.5rem] lg:container lg:mb-[4rem] lg:pt-[3.125rem] ${
        className ? className : ""
      }`}
    >
      <div className="mb-[2.25rem] pl-4 lg:mb-[3rem] lg:pl-0 ">
        <CategoryHeading categoryName="Words from our Customers" />
      </div>

      <div className="hidden lg:block">
        <TestimonialsCarousel
          slidesPerView={3}
          spaceBetween={24}
          carouselItems={data}
          centeredSlides={true}
          PreviewCard={TestimonialCard}
          cardClassName="min-h-[25rem] min-[1280px]:min-h-[20rem] "
        />
      </div>

      <div className="block pl-4 lg:hidden">
        <TestimonialsCarousel
          spaceBetween={16}
          slidesPerView={1.1}
          carouselItems={data}
          centeredSlides={true}
          PreviewCard={TestimonialCard}
          cardClassName="min-h-[16.75rem]"
        />
      </div>
    </div>
  );
};

export default Testimonials;
