import React from "react";

import BrandCard from "@/components/data-display/card/brandCard";
import MultiCardCarousel from "@/components/data-display/multicard-carousel";
import useViewport from "@/hooks/useViewport";

function BrandCarousel({ brandList }) {
  const { width: windowWidth } = useViewport();

  return (
    <div>
      <MultiCardCarousel
        carouselItems={brandList}
        PreviewCard={BrandCard}
        spaceBetween={25}
        isAutoPlay={true}
        slidesPerView={Math.min(Math.round(windowWidth / 150), 7)}
        sliderClassName="pb-[1rem] px-[1.5rem] xl:px-[3rem] lg:px-[2rem] m-auto"
      />
    </div>
  );
}

export default BrandCarousel;
