import Image from "next/image";

import PropTypes from "prop-types";

import { QuoteMarksIcon } from "../../icon";

const TestimonialCard = ({
  comment,
  author_name,
  className,
  designation,
  organization,
  author_picture,
}) => {
  return (
    <div
      className={`flex flex-col justify-between rounded-xl border p-[1rem] md:p-[1.5rem] ${className}`}
    >
      <div>
        <div className="mb-[1rem] md:mb-[1.375rem]">
          <QuoteMarksIcon />
        </div>
        <p
          title={comment}
          className="mb-[1.25rem] line-clamp-5 text-[0.875rem] md:mb-[1.75rem] md:text-[1rem] "
        >
          {comment}
        </p>
      </div>

      <div className="flex items-center text-[0.875rem] md:text-[1rem]">
        {author_picture && (
          <div className="relative mr-[0.85rem] min-h-[3.5rem] min-w-[3.5rem]">
            <Image
              fill
              alt={author_name}
              src={author_picture}
              className="rounded-full object-cover"
            />
          </div>
        )}

        <div>
          <p className="mb-[0.15rem] font-semibold">{author_name}</p>
          <p>
            <span>{designation}</span>
            {organization && <span>, {organization}</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

TestimonialCard.propTypes = {
  comment: PropTypes.string,
  className: PropTypes.string,
  author_name: PropTypes.string,
  designation: PropTypes.string,
  organization: PropTypes.string,
  author_picture: PropTypes.string,
  address: PropTypes.string,
};

export default TestimonialCard;
