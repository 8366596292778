import Image from "next/image";
import Link from "next/link";

import PropTypes from "prop-types";

function CategoryCard(props) {
  const { name, imageUrl, slug, className } = props;

  return (
    <Link href={slug}>
      <div className={`${className} flex flex-col items-center`}>
        <div className="relative h-[5rem] w-[5rem] md:h-[8.75rem] md:w-[8.75rem] xl:h-[11.25rem] xl:w-[11.25rem]">
          <Image alt={name} fill src={imageUrl} className="rounded-full" />
        </div>
        <h4
          className={`mt-[0.813rem] text-center text-[0.75rem] font-medium text-grayDisplay-900 md:text-[1.125rem]`}
        >
          {name}
        </h4>
      </div>
    </Link>
  );
}

CategoryCard.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  slug: PropTypes.string,
  className: PropTypes.string,
};

CategoryCard.defaultProps = {
  className: "",
  slug: "/",
};
export default CategoryCard;
