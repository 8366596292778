import Image from "next/image";
import Link from "next/link";

function SmallBanner({ bannerList }) {
  const MAX_BANNER_IMAGES = 2;

  return (
    <div className="grid gap-5 lg:grid-cols-2 lg:gap-[1.875rem]">
      {bannerList.slice(0, MAX_BANNER_IMAGES).map((bannerData) => (
        <Link key={bannerData.banner} href={bannerData.link}>
          <Image
            src={bannerData.banner}
            width={1170}
            height={620}
            alt="Promotional Banner"
            quality={40}
          />
        </Link>
      ))}
    </div>
  );
}

export default SmallBanner;
