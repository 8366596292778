import { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { A11y, Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import SlideNextIcon from "@/icons/slide-next.svg";

function MultiCardCarousel(props) {
  const {
    carouselItems,
    PreviewCard,
    isNextBtnVisible,
    isPrevBtnVisible,
    slidesPerView,
    spaceBetween,
    sliderClassName,
    breakpoints,
    isAutoPlay,
  } = props;
  const swiperRef = useRef(null);
  let MAX_RENDER_ELEMENT = 6;

  const [renderItems, setRenderItem] = useState(carouselItems);

  useEffect(() => {
    if (
      carouselItems.length > MAX_RENDER_ELEMENT &&
      renderItems.length < MAX_RENDER_ELEMENT * 2
    ) {
      setRenderItem([...renderItems, ...renderItems]);
    }
  }, [carouselItems]);

  return (
    <Swiper
      className="relative"
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      loop={true}
      autoplay={{
        delay: 4000,
      }}
      style={{
        paddingLeft: isPrevBtnVisible && "64px",
        paddingRight: isNextBtnVisible && "64px",
      }}
      ref={swiperRef}
      modules={isAutoPlay ? [A11y, Autoplay] : [A11y]}
      breakpoints={breakpoints}
    >
      {renderItems &&
        renderItems.map((carouselItem, i) => (
          <SwiperSlide key={i} className={sliderClassName}>
            <PreviewCard
              name={carouselItem.name}
              imageUrl={carouselItem.logo}
              slug={carouselItem.slug}
            />
          </SwiperSlide>
        ))}

      <>
        {isPrevBtnVisible && (
          <button
            className="absolute left-0 top-[50%] h-[3rem] w-[3rem] translate-y-[-50%] rounded-full border border-grayDisplay-100 bg-white"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            Prev
          </button>
        )}

        {isNextBtnVisible && (
          <button
            className="absolute right-0 top-[50%] z-50 hidden h-[3rem] w-[3rem] translate-y-[-50%] rounded-full border border-grayDisplay-100 bg-white  md:block"
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <SlideNextIcon />
          </button>
        )}
      </>
    </Swiper>
  );
}

MultiCardCarousel.defaultProps = {
  isNextBtnVisible: false,
  isPrevBtnVisible: false,
  slidesPerView: 6,
  spaceBetween: 90,
  isAutoPlay: false,
};

MultiCardCarousel.propTypes = {
  isNextBtnVisible: PropTypes.bool,
  isPrevBtnVisible: PropTypes.bool,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  sliderClassName: PropTypes.string,
};

export default MultiCardCarousel;
