import Image from "next/image";
import Link from "next/link";

function BrandCard({ name, imageUrl, slug }) {
  return (
    <Link href={`/brands/${slug}`}>
      <Image height={300} width={300} alt={name} src={imageUrl} quality={40} />
    </Link>
  );
}

export default BrandCard;
