import PropTypes from "prop-types";

function CategoryHeading({ categoryName }) {
  return (
    <div className="flex items-center space-x-[0.75rem]">
      <span className="block h-[1.5rem] w-[0.25rem] bg-primary" />
      <h3 className="text-[1.063rem] font-semibold text-grayDisplay-900 lg:text-[1.5rem]">
        {categoryName}
      </h3>
    </div>
  );
}

CategoryHeading.defaultProps = {
  categoryName: "Controller",
};

CategoryHeading.propTypes = {
  categoryName: PropTypes.string,
};

export default CategoryHeading;
