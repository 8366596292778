import PropTypes from "prop-types";
import { A11y, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

function TestimonialsCarousel({
  carouselItems,
  PreviewCard,
  spaceBetween,
  slidesPerView,
  cardClassName,
}) {
  return (
    <Swiper
      effect={"fade"}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 4000,
      }}
      className="testimonials-carousel"
      modules={[Pagination, Autoplay, A11y]}
    >
      {carouselItems.map((el, i) => (
        <SwiperSlide key={i} className="pb-[2.5rem] lg:pb-[3.5rem]">
          <PreviewCard className={cardClassName} {...el} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

TestimonialsCarousel.defaultProps = {
  slidesPerView: 3,
  spaceBetween: 30,
  centeredSlides: false,
};

TestimonialsCarousel.propTypes = {
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  PreviewCard: PropTypes.any,
  carouselItems: PropTypes.array,
  centeredSlides: PropTypes.bool,
  cardClassName: PropTypes.string,
};

export default TestimonialsCarousel;
