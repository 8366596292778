import Image from "next/image";

import { A11y, Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

function BannerCarousel({ bannerList }) {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className}"></span>`;
    },
  };

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      effect={"fade"}
      loop={true}
      pagination={pagination}
      autoplay={{
        delay: 2000,
      }}
      modules={[Pagination, Autoplay, A11y, EffectFade]}
      className="rounded-[0.5rem] md:rounded-[0.875rem]"
    >
      {bannerList.map((bannerData, i) => (
        <SwiperSlide key={i}>
          <div className="relative aspect-[93/34]">
            <Image
              fill
              priority
              quality={40}
              alt="slider image"
              src={bannerData.banner}
              sizes="(max-width: 1023px) 100vw, 70vw"
              className="rounded-[0.5rem] object-cover md:rounded-[0.875rem]"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default BannerCarousel;
