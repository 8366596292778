import Image from "next/image";
import Link from "next/link";

import PropsType from "prop-types";

function DeliveryBanner({ redirectUrl, bannerUrl }) {
  return (
    <Link href={redirectUrl}>
      <Image
        src={bannerUrl}
        width={2880}
        height={900}
        quality={40}
        alt="Delivery Banner"
      />
    </Link>
  );
}

DeliveryBanner.defaultProps = {
  bannerUrl:
    "https://robodocstorage.blob.core.windows.net/static-images/last-banner.png",
};

DeliveryBanner.propTypes = {
  bannerUrl: PropsType.string,
};

export default DeliveryBanner;
