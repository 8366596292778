import Image from "next/image";
import Link from "next/link";

import PropsType from "prop-types";

function BuyNowBanner({ redirectUrl, bannerUrl }) {
  return (
    <Link href={redirectUrl}>
      <Image
        src={bannerUrl}
        width={2400}
        height={620}
        quality={40}
        alt="Buy Now"
        className="rounded-[0.5rem] lg:rounded-[0.75rem]"
      />
    </Link>
  );
}

BuyNowBanner.defaultProps = {
  bannerUrl:
    "https://robodocstorage.blob.core.windows.net/static-images/second-banner.png",
};

BuyNowBanner.propTypes = {
  bannerUrl: PropsType.string,
};

export default BuyNowBanner;
