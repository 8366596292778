import Link from "next/link";

import PropTypes from "prop-types";

import ProductCard from "@/components/data-display/card/product";
import CategoryHeading from "@/components/data-display/categoryHeading";
import { ArrowRightIcon } from "@/components/data-display/icon";

function CategoryProducts({ category }) {
  const MAX_PRODUCT_AMOUNT = 4;

  return (
    <div className="space-y-[2.75rem]">
      <div className="flex items-center justify-between">
        <CategoryHeading categoryName={category.name} />
        <div className="tet-[1.125rem] flex h-[2.625rem] w-[7.188rem] cursor-pointer items-center justify-center rounded-[0.5rem] bg-primary-400/5 font-medium text-primary">
          <Link href={`${category.redirectUrl}`}>
            <div className="flex items-center space-x-[0.813rem]">
              <p>View All</p>
              <ArrowRightIcon className="h-[1rem] w-[1rem] text-primary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-[0.75rem] lg:grid-cols-3 lg:gap-[1.563rem] xl:grid-cols-4">
        {category?.products?.length > 0 &&
          category.products
            .slice(0, MAX_PRODUCT_AMOUNT)
            .map((product) => (
              <ProductCard key={product.slug} product={product} />
            ))}
      </div>
    </div>
  );
}

CategoryProducts.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    redirectUrl: PropTypes.string,
    products: PropTypes.array,
  }),
};

export default CategoryProducts;
