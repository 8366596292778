import Image from "next/image";

import BannerCarousel from "@/components/data-display/banner-carousel";

function HeroSection({ carouselImages, isSubBanner, subBannerImages }) {
  const MAX_SUB_BANNER = 3;

  return (
    <div className="space-y-[0.375rem] lg:space-y-[1.25rem]">
      <BannerCarousel bannerList={carouselImages} />
      {isSubBanner && subBannerImages?.length > 0 && (
        <div className="grid grid-cols-3 gap-4">
          {subBannerImages.slice(0, MAX_SUB_BANNER).map((bannerImage) => (
            <div key={bannerImage.banner} className="relative aspect-[37/26]">
              <Image
                fill
                priority
                quality={40}
                sizes="33vw"
                alt="Special Ad"
                src={bannerImage.banner}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default HeroSection;
