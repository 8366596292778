import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getLandingPageData } from "@/api/server/landing-page";
import BuyNowBanner from "@/components/data-display/banner/buy-now-banner";
import FreeDeliveryBanner from "@/components/data-display/banner/free-delivery-banner";
import SideMenu from "@/components/navigation/sidemenu";
import Testimonials from "@/components/sections/Testimonials";
import BrandCarousel from "@/components/sections/brandCarousel";
import CategoryProducts from "@/components/sections/categoryProducts";
import DeliveryBanner from "@/components/sections/deliveryBanner";
import FeaturedCategories from "@/components/sections/featured-categories";
import HeroSection from "@/components/sections/heroSection";
import SmallBanner from "@/components/sections/smallBanner";
import MetaData from "@/components/seo/MetaData";
import { getWishlistState } from "@/store/feature/wishlistSlice";
import { revalidationTime } from "@/utils/constants/server-side.constants";
import { CategoryVisibility } from "@/utils/feature-flags";
import {
  updateCategoryListWithWishlistStatus,
  updateListWithWishlistStatus,
} from "@/utils/helpers/wishlist";

export default function Home({ landingPageData }) {
  const { wishlistItems } = useSelector(getWishlistState);

  const [popularProduct, setPopularProduct] = useState(
    landingPageData?.popular_products
  );
  const [newProduct, setNewProduct] = useState(landingPageData?.new_products);
  const [dealProduct, setDealProduct] = useState(
    landingPageData?.deal_products
  );
  const [specialProduct, setSpecialProduct] = useState(
    landingPageData?.special_products
  );
  const [categoryList, setCategoryList] = useState(
    landingPageData?.category_products
  );

  useEffect(() => {
    if (!Object.keys(wishlistItems)?.length) return;
    setPopularProduct(
      updateListWithWishlistStatus(popularProduct, wishlistItems)
    );
    setNewProduct(updateListWithWishlistStatus(newProduct, wishlistItems));
    setDealProduct(updateListWithWishlistStatus(dealProduct, wishlistItems));
    setSpecialProduct(
      updateListWithWishlistStatus(specialProduct, wishlistItems)
    );
    setCategoryList(
      updateCategoryListWithWishlistStatus(categoryList, wishlistItems)
    );
  }, [Object.keys(wishlistItems)?.length]);

  return (
    <>
      <MetaData
        {...landingPageData?.metadata}
        schema={landingPageData?.schema}
      />

      <div className="container">
        <div className="mb-[2.25rem] mt-[1.25rem] flex justify-between lg:mb-[3.125rem]">
          <div className="hidden w-[28%] min-w-[16.875rem] lg:block 2xl:w-[20%]">
            <SideMenu sideMenuData={landingPageData?.left_categories} />
          </div>

          <div className="w-full lg:w-[70%] 2xl:w-[78%]">
            <HeroSection
              carouselImages={landingPageData?.main_sliders}
              isSubBanner={landingPageData?.special_ads?.status}
              subBannerImages={landingPageData?.special_ads?.items}
            />
          </div>
        </div>

        {CategoryVisibility.isFeaturedCategory && (
          <section className="mb-[3.5rem] lg:mb-[5.375rem]">
            <FeaturedCategories
              featureCategoryName="Featured Categories"
              categoryData={landingPageData?.featured_categories}
            />
          </section>
        )}

        {popularProduct?.length > 0 && (
          <section className="mb-[3.5rem] lg:mb-[1.875rem]">
            <CategoryProducts
              category={{
                name: "Popular Products",
                redirectUrl: "/popular-products",
                products: popularProduct,
              }}
            />
          </section>
        )}

        {newProduct?.length > 0 && (
          <section className="mb-[3.5rem] lg:mb-[1.875rem]">
            <CategoryProducts
              category={{
                name: "New Products",
                redirectUrl: "/new-products",
                products: newProduct,
              }}
            />
          </section>
        )}

        {landingPageData?.promotional_ads_one?.status && (
          <section className="mb-[3.5rem] lg:mb-[5rem] lg:pt-[3.125rem]">
            <BuyNowBanner
              redirectUrl={landingPageData.promotional_ads_one.link}
              bannerUrl={landingPageData.promotional_ads_one.banner}
            />
          </section>
        )}

        {dealProduct?.length > 0 && (
          <section className="mb-[3.5rem] lg:mb-[1.875rem]">
            <CategoryProducts
              category={{
                name: "Today's Deal",
                redirectUrl: "/today-deals",
                products: dealProduct,
              }}
            />
          </section>
        )}

        {landingPageData?.promotional_ads_two?.status && (
          <section className="mb-[3.5rem] lg:mb-[5rem] lg:pt-[3.125rem]">
            <FreeDeliveryBanner
              redirectUrl={landingPageData.promotional_ads_two.link}
              bannerUrl={landingPageData.promotional_ads_two.banner}
            />
          </section>
        )}

        {specialProduct?.length > 0 && (
          <section className="mb-[3.5rem] lg:mb-[1.875rem]">
            <CategoryProducts
              category={{
                name: "Special Products",
                redirectUrl: "/special-products",
                products: specialProduct,
              }}
            />
          </section>
        )}

        {landingPageData?.promotional_ads_three?.status && (
          <section className="mb-[3.5rem] lg:mb-[5rem] lg:pt-[3.125rem]">
            <SmallBanner
              bannerList={landingPageData.promotional_ads_three.items}
            />
          </section>
        )}

        {categoryList?.length > 0 &&
          categoryList.map((categoryData) => {
            if (categoryData.products?.length > 0) {
              return (
                <section
                  key={categoryData.slug}
                  className="mb-[3.5rem] lg:mb-[1.875rem]"
                >
                  <CategoryProducts
                    category={{
                      name: categoryData.name,
                      products: categoryData.products,
                      redirectUrl: `/category/${categoryData.slug}`,
                    }}
                  />
                </section>
              );
            }
          })}
      </div>

      {landingPageData?.testimonials?.length > 0 && (
        <Testimonials data={landingPageData?.testimonials} />
      )}

      {landingPageData?.brand_sliders?.length > 0 && (
        <section className="mb-[3.5rem] lg:mb-[3.5rem] lg:pt-[1.625rem]">
          <BrandCarousel brandList={landingPageData?.brand_sliders} />
        </section>
      )}

      {landingPageData?.promotional_ads_four?.status && (
        <DeliveryBanner
          redirectUrl={landingPageData.promotional_ads_four?.link}
          bannerUrl={landingPageData.promotional_ads_four?.banner}
        />
      )}
    </>
  );
}

export const getStaticProps = async () => {
  const res = await getLandingPageData();
  return {
    props: { landingPageData: res.data.data },
    revalidate: revalidationTime,
  };
};
