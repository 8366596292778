import PropTypes from "prop-types";

import CategoryCard from "@/components/data-display/card/category-card";
import CategoryHeading from "@/components/data-display/categoryHeading";

export default function FeaturedCategories({
  featureCategoryName,
  categoryData,
}) {
  return (
    <>
      <div>
        <CategoryHeading categoryName={featureCategoryName} />
      </div>
      <div className="mt-[2.75rem] grid grid-cols-3 justify-between gap-x-[1.5rem] gap-y-[3.125rem] md:w-fit md:grid-cols-4 lg:grid-cols-5 lg:gap-x-[3rem] xl:grid-cols-6 xl:gap-x-[4rem] 2xl:gap-x-[5rem]">
        {categoryData.slice(0, 6).map((category) => {
          return (
            <CategoryCard
              key={category.slug}
              name={category.name}
              imageUrl={category.logo}
              slug={`/category/${category.slug}`}
            />
          );
        })}
      </div>
    </>
  );
}
FeaturedCategories.propTypes = {
  featureCategoryName: PropTypes.string,
  categoryData: PropTypes.array,
};
