import Link from "next/link";

import { ArrowRightIcon } from "@/components/data-display/icon";

function SideMenu({ sideMenuData }) {
  return (
    <ul className="h-full w-full rounded-[0.625rem] border border-grayDisplay-100 bg-[#FAFBFCE6]  px-[0.75rem] py-[1.188rem] text-grayDisplay-700">
      {sideMenuData?.length &&
        sideMenuData.map((menuData) => (
          <li className="group relative w-full" key={menuData.slug}>
            {menuData.child.length > 0 ? (
              <Link href={`/category/${menuData.slug}`}>
                <div className="flex cursor-pointer items-center justify-between rounded-[0.625rem] px-[0.75rem] py-[0.5rem] hover:bg-white hover:text-primary">
                  <p>{menuData.name}</p>

                  <ArrowRightIcon className="hover:text-primary" />
                </div>
              </Link>
            ) : (
              <Link href={`/category/${menuData.slug}`}>
                <div className="flex cursor-pointer items-center justify-between rounded-[0.625rem] px-[0.75rem] py-[0.5rem] hover:bg-white hover:text-primary">
                  <p>{menuData.name}</p>
                </div>
              </Link>
            )}

            {!!menuData?.child?.length && (
              <div className="absolute left-[100%] top-0 z-50 hidden hover:block group-hover:block">
                <ul className="ml-[0.75rem] min-w-[15.5rem] space-y-[1.25rem] rounded-[0.75rem] border border-grayDisplay-100 bg-white py-[1.25rem]">
                  {menuData.child.map((subMenuData) => (
                    <li
                      key={subMenuData.slug}
                      className="cursor-pointer hover:text-primary"
                    >
                      <Link
                        className="block px-[1.5rem]"
                        href={`/category/${subMenuData.slug}`}
                      >
                        {subMenuData.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
    </ul>
  );
}

export default SideMenu;
